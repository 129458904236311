<template>
  <chat-window
    :currentUserId="currentUserId"
    :rooms="rooms"
    :messages="messages"
    :show-audio="false"
    :show-files="false"
  />
</template>

<script>
import ChatWindow from 'vue-advanced-chat'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'

export default {
  components: {
    ChatWindow,
  },

  data() {
    return {
      rooms: [
        {
          roomId: 1,
          roomName: 'Касым клиент из WhatsApp',
          lastMessage: {
            content: 'Здесь будет последняя сообщения',
            sender_id: 1234,
            username: 'John Doe',
            timestamp: '10:20',
            date: 123242424,
            seen: true,
            new: true,
          },
          users: [
            {
              _id: 1234,
              username: 'John Doe',
              status: {
                state: 'online',
                last_changed: 'today, 14:30',
              },
            },
            {
              _id: 4321,
              username: 'John Snow',
              status: {
                state: 'offline',
                last_changed: '14 July, 20:00',
              },
            },
          ],
          typingUsers: [4321],
        },
        {
          roomId: 2,
          roomName: 'Рома клиент из Инстаграмма',
          lastMessage: {
            content: 'Здесь будет последняя сообщения',
            sender_id: 1234,
            username: 'John Doe',
            timestamp: '10:20',
            date: 123242424,
            seen: true,
            new: true,
          },
          users: [
            {
              _id: 1234,
              username: 'John Doe',
              status: {
                state: 'online',
                last_changed: 'today, 14:30',
              },
            },
            {
              _id: 4321,
              username: 'John Snow',
              status: {
                state: 'offline',
                last_changed: '14 July, 20:00',
              },
            },
          ],
          typingUsers: [4321],
        },
      ],
      messages: [],
      currentUserId: 1234,
    }
  },
}
</script>
